
import { onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { credentialSheetCsv } from "@/store/api/devices";
export default {
  components: {},
  setup() {
    const urls = ref([]);
    const newUrls = ref([]);
    const searchquery = ref("");
    onMounted(async () => {
      setCurrentPageBreadcrumbs("Credential Sheets", [""]);
      const result = await credentialSheetCsv();
      urls.value = result.data.data;
      newUrls.value = result.data.data;
    });
    //filter fuction
    const filtereddata = () => {
      const filteredvalue = newUrls.value.filter(
        (item: any) =>
          item.toLowerCase().indexOf(searchquery.value.toLowerCase()) !== -1
      );
      urls.value = filteredvalue;
    };
    return {
      urls,
      newUrls,
      searchquery,
      filtereddata,
    };
  },
};
